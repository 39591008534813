@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

:root {
  --para: #444444;
  --bgcolor: #0e71b9;
}

* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none !important;
}

/* *::selection{
    color: var(--bgcolor);
} */
*::-webkit-scrollbar {
  height: 1rem;
  width: 1rem;
}

*::-webkit-scrollbar-track {
  background-color: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--bgcolor);
  border-radius: 5rem;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 2rem;
}
body {
}
ul,
li {
  list-style: none;
  padding: 0 !important;
}

.header .grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .grid .profile {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  /* cursor: pointer; */
}

.header .grid .profile p {
  margin: 0;
  font-size: 14px;
  padding-right: 1rem;
}

/* navbar start 
==========================*/

.nav {
  background-color: #04225e;
  padding: 1.5rem 0;
}
.nav a {
  color: #fff;
  margin-right: 1.8rem;
  font-size: 14px;
  padding: 1rem;
  /* transition: 1s ease-in; */
  text-transform: capitalize;
}

.nav a:hover {
  /* color: #000; */
  font-weight: 100;
}
.nav {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  z-index: 1000;
}

.nav .menu-box i {
  color: #fff;
  font-size: 16px;
}
#menu-btn {
  display: none;
}

/* navbar end
=========================== */

/* index start 
===========================*/

/* slider start 
===========================*/

.swiper-button-next {
  right: 0%;
}

.swiper-button-prev {
  left: 0%;
}

.swiper-button-next,
.swiper-button-prev {
  padding: 3rem 2rem;
  background: #fff;
  top: 45%;
  opacity: 0.7;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: var(--main-color);
  font-size: 2rem;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  opacity: 1;
}

.home-slider .slider-1 .video {
  width: 100vw;
  height: 81vh;
  object-fit: cover;
}

.carousel-caption {
  top: 15%;
  transform: translate(0px, -0px);
}
.carousel-caption .heading {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  width: 75%;
  margin: 0 auto;
}
.carousel-caption .title {
  font-size: 14px;
  color: #fff;
  width: 70%;
  margin: 4rem auto;
}
.carousel-caption .h-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 3rem 0;
  justify-content: center;
}

.carousel-caption .h-btns .abtn1 {
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: var(--bgcolor);
  font-size: 15px;
  padding: 1.8rem 2rem 1.6rem;
  border: 2px solid var(--bgcolor);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  text-transform: uppercase;
}
.carousel-caption .h-btns .abtn1:hover {
  color: var(--bgcolor);
}

.carousel-caption .h-btns .abtn1:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.carousel-caption .h-btns .abtn1:hover:before {
  top: -30px;
  left: -30px;
}
.carousel-caption .h-btns .abtn1:active:before {
  background: var(--bgcolor);
  transition: 0s;
}

.carousel-caption .h-btns .abtn2 {
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: transparent;
  font-size: 15px;
  padding: 1.8rem 2rem 1.6rem;
  border: 2px solid #fff;
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  text-transform: uppercase;
}
.carousel-caption .h-btns .abtn2:hover {
  color: var(--bgcolor);
}

.carousel-caption .h-btns .abtn2:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.carousel-caption .h-btns .abtn2:hover:before {
  top: -30px;
  left: -30px;
}
.carousel-caption .h-btns .abtn2:active:before {
  background: var(--bgcolor);
  transition: 0s;
}

/* slider end 
===========================*/

/* video start *
===========================*/
.video__wrapper {
  margin: 20px 0;
  display: inline-block;
  position: relative;
}
.video1,
.video2 {
  width: 100%;
  max-width: 100%;
  max-height: auto;
  display: inline-block;
  vertical-align: top;
  border-radius: 25px;
}

.video3 {
  width: 100%;
  max-width: 100%;
  height: 84%;
  /* max-height: 100%; */
  display: inline-block;
  vertical-align: top;
  border-radius: 25px;
  overflow: hidden;
}
.video3 video {
  object-fit: cover;
}

.video__play-button {
  margin: 0;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0;
  background-color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;
  z-index: 2;
  transition: all 200ms ease-in-out;
  border-radius: 25px;
}

.video__play-button-icon {
  width: 15%;
  transition: all 200ms ease-in-out;
}

.video__play-button-icon--play polygon {
  transform-origin: 50% 50%;
  transition: all 200ms ease-in-out;
}

.video__play-button-icon--play:hover polygon {
  transform: scale(1.5);
}

.video__play-button[data-button-state="pause"] .video__play-button-icon--pause {
  display: none;
}

.video__play-button[data-button-state="play"] .video__play-button-icon--play {
  display: none;
}

.video__play-button[data-button-state="play"] .video__play-button-icon {
  opacity: 0;
}

.video__play-button[data-button-state="play"]:hover .video__play-button-icon {
  opacity: 1;
}

.video__wrapper svg {
  width: 100px;
  height: 100px;
  fill: #fff;
  stroke: #fff;
  cursor: pointer;
  background-color: rgba(black, 0.2);
  border-radius: 50%;
  opacity: 0.9;
}

/* video end *
===========================*/

.section2 {
  margin: 4rem 0;
}
.section2 .box {
  padding: 0 1.5rem;
}
.section2 .heading {
  font-weight: bold;
  font-size: 48px;
  text-align: center;
  color: #04225e;
}

.section2 h3 {
  font-size: 30px;
  font-weight: bold;
  color: var(--bgcolor);
  text-transform: uppercase;
  margin: 2rem 0;
}
.section2 ul {
  position: relative;
}
.section2 ul li {
  font-size: 16px;
  color: #7f7f7f;
  position: relative;
  margin-left: 2.2rem;
  margin-bottom: 1rem;
  line-height: 2;
}
.section2 ul li:before {
  content: "•";
  position: absolute;
  left: -2.5rem;
  color: #000;
  font-size: 6rem;
  line-height: 3rem;
}
.section2 ul li span {
  font-size: 16px;
  color: #7f7f7f;
  font-weight: bold;
}
.section2 .video1 {
  box-shadow: 0px 2px 15px -2px var(--para);
}

.section-add {
  /* background-color: var(--bgcolor); */
  padding: 4rem 0;
}
.section-add .back-img {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(./images/hbg-1.jpg) no-repeat;
  background-size: cover;
  background-position: left;
  height: auto;
  padding: 6rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section-add .back-img h5 {
  font-size: 26px;
  font-weight: bold;
  color: #fff;
}
.section-add .back-img p {
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
}
.section-add .back-img p span {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  margin-top: 1.5rem;
}
.section-add .back-img img {
  margin-right: 1.5rem;
  margin-top: 1.5rem;
}
.section3 {
  margin: 5rem 0;
}
/* .section3 .cover-img {
  background: linear-gradient(rgba(14, 113, 185, 0.3), rgba(0, 0, 0, 1)),
    url("./images/hbg-3.jpg") no-repeat;
  background-size: cover;
  border-radius: 20px;
  padding: 5rem;
} */

.section3 .cover-img .heading {
  font-size: 48px;
  font-weight: bold;
  width: 80%;
  color: #04225e;
  margin-top: 2rem;
}
.section3 .cover-img .title {
  font-size: 14px;
  /* width: 62%; */
  margin: 3rem 0;
  color: var(--para);
}

.section3 .cover-img .cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.section3 .cover-img .cards .box {
  /* background-color: var(--bgcolor); */
  /* padding: 2rem; */
  flex: 1 1 20rem;
  text-align: center;
  border-radius: 10px;
}
.section3 .cover-img .cards .box img {
  /* background-color: var(--bgcolor); */
  /* padding: 2rem; */
  border-radius: 10px;
}
.section3 .cover-img .cards .box h6 {
  font-size: 16px;
  font-weight: bold;
  margin: 1rem 0;
  color: #04225e;
}
.section3 .cover-img .cards .box p {
  font-size: 12px;
  color: var(--para);
}

.section4 {
  margin: 8rem 0 2rem;
}
.section4 .heading {
  color: #000;
  font-size: 48px;
  font-weight: bold;
  margin-top: 3rem;
}
.section4 .title {
  color: var(--para);
  font-size: 14px;
  margin: 1rem 0;
  padding-bottom: 1rem;
}

.section5 .cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.section5 .cards .box {
  padding: 2rem;
  flex: 1 1 20rem;
  text-align: center;
  border-radius: 10px;
}
.section5 .cards .box h6 {
  font-size: 16px;
  font-weight: bold;
  margin: 1rem;
}
.section5 .cards .box p {
  font-size: 14px;
  color: var(--para);
  padding: 0.5rem;
}

/* footer start  
===========================*/

.footer {
  background-color: #04225e;
  padding: 5rem 0 2rem;
}
/* .footer .cards{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
} */

.footer .media-box {
  display: flex;
  gap: 2rem;
  border-bottom: 1px solid #fff;
  margin-top: 3rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}
.footer .media-box h6 {
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
  text-transform: capitalize;
}
.footer .box h6 {
  font-size: 1.7rem;
  color: #fff;
  font-weight: bold;
}
.footer .box a {
  font-size: 1.3rem;
}
.footer .media-box i {
  font-size: 18px;
  color: #fff;
}
.footer .container .cards {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* background-color: red; */
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer .container .cards .box {
  width: auto;
}
.footer .copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}
.footer .copyright p {
  color: #fff;
  font-size: 10px;
}
.footer .box {
  color: white;
}
.footer .box .links {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* footer end 
===========================*/

/* index end  
===========================*/

/* freelancer list start
===========================*/

.f-list-1 {
  padding: 2rem 0;
}
.f-list-1 .bg-col {
  background-color: rgba(217, 217, 217, 0.6);
  border-radius: 10px;
  padding: 2rem;
}
.f-list-1 .bg-col h6 {
  font-size: 15px;
  font-weight: bold;
  color: #345fb4;
}
.f-list-1 .bg-col form {
  padding: 1rem 0;
}
.f-list-1 .bg-col form .list-flex {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  gap: 1rem;
}
.f-list-1 .bg-col form .list-flex .box p {
  font-size: 12px;
  font-weight: 700;
  color: #8e8e8e;
}
.f-list-1 .bg-col form .list-flex .box .input {
  width: 100%;
  padding: 1rem;
  font-size: 1.8rem;
  margin: 1rem 0;
  min-height: 4rem;
  border-radius: 5px;
  background: white;
}
.f-list-1 .bg-col form .list-flex .box .input::placeholder {
  color: #000;
  opacity: 1;
  font-size: 12px;
}
.f-list-1 .bg-col form .list-flex .box .input,
select::placeholder {
  color: #000;
  opacity: 1;
  font-size: 12px;
  background: white;
}

.f-list-1 .bg-col form .buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-top: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
}
.f-list-1 .bg-col form .buttons .left-btn {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.f-list-1 .bg-col form .buttons a {
  padding: 1rem 0;
  width: 17rem;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
}
.f-list-1 .bg-col form .buttons .abtn1 {
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: var(--bgcolor);
  border: 1px solid var(--bgcolor);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  text-transform: uppercase;
}
.f-list-1 .bg-col form .buttons .abtn1:hover {
  color: var(--bgcolor);
}

.f-list-1 .bg-col form .buttons .abtn1:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.f-list-1 .bg-col form .buttons .abtn1:hover:before {
  top: -30px;
  left: -30px;
}
.f-list-1 .bg-col form .buttons .abtn1:active {
  color: #fff;
}
.f-list-1 .bg-col form .buttons .abtn1:active:before {
  background: var(--bgcolor);
  transition: 0s;
}

.f-list-1 .bg-col form .buttons .abtn2 {
  display: inline-block;
  position: relative;
  background-color: var(--para);
  border: 1px solid var(--para);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  text-transform: uppercase;
}
.f-list-1 .bg-col form .buttons .abtn2:hover {
  color: var(--para);
}

.f-list-1 .bg-col form .buttons .abtn2:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.f-list-1 .bg-col form .buttons .abtn2:hover:before {
  top: -30px;
  left: -30px;
}
.f-list-1 .bg-col form .buttons .abtn2:active {
  color: var(--bgcolor);
  background-color: black;
  color: white;
}
.f-list-1 .bg-col form .buttons .abtn2:active:before {
  /* background: #fff; */
  color: white;
  background-color: var(--para);

  transition: 0s;
}

.f-list-1 .bg-col form .buttons .abtn3 {
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: var(--bgcolor);
  border: 1px solid var(--bgcolor);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  text-transform: uppercase;
}
.f-list-1 .bg-col form .buttons .abtn3:hover {
  color: var(--bgcolor);
}

.f-list-1 .bg-col form .buttons .abtn3:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.f-list-1 .bg-col form .buttons .abtn3:hover:before {
  top: -30px;
  left: -30px;
}
.f-list-1 .bg-col form .buttons .abtn3:active {
  color: var(--bgcolor);
}
.f-list-1 .bg-col form .buttons .abtn3:active:before {
  background: #fff;
  transition: 0s;
}

.f-list-2 {
  padding-bottom: 2rem;
}
.f-list-2 .bg-col {
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  padding: 3rem 2rem;
}
.f-list-2 .bg-col .fist {
  text-align: center;
  /* padding: 0 !important; */
}
.f-list-2 .bg-col .fist h5 {
  font-size: 16px;
  color: var(--bgcolor);
  font-weight: bold;
}
.f-list-2 .bg-col .fist h6 {
  font-size: 16px;
  color: var(--bgcolor);
  font-weight: bold;
  margin: 1rem 0;
}
.f-list-2 .bg-col .fist p {
  font-size: 12px;
  margin: 1rem 0;
  line-height: 19px;
}

.f-list-2 .bg-col .second .box-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.f-list-2 .bg-col .second .box-flex div {
  display: flex;
  gap: 1rem;
  padding-right: 1rem;
}
.f-list-2 .bg-col .second h5 {
  font-size: 12px;
}
.f-list-2 .bg-col .second .border-line {
  border-right: 2px solid #d9d9d9;
  padding-right: 1rem;
}
.f-list-2 .bg-col .second p {
  font-size: 12px;
  color: var(--para);
  padding: 1rem 0;
  margin: 2rem 0;
}
.f-list-2 .bg-col .second h6 {
  font-size: 12px;
  font-weight: bold;
}
.f-list-2 .bg-col .second .a-buttos {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  margin: 2rem 0;
}
.f-list-2 .bg-col .second .a-buttos a {
  font-size: 10px;
  width: 9rem;
  text-align: center;
  border-radius: 10px;
  color: #000;
  padding: 0.5rem 0;
}
.f-list-2 .bg-col .second .a-buttos a:nth-child(1) {
  background-color: #f88a3b;
}
.f-list-2 .bg-col .second .a-buttos a:nth-child(2) {
  background-color: #e0d03e;
}
.f-list-2 .bg-col .second .a-buttos a:nth-child(3) {
  background-color: #345fb4;
  color: #fff;
}

.f-list-2 .bg-col .third a {
  padding: 1rem 0;
  width: 17rem;
  font-size: 12px;
  text-align: center;
}
.f-list-2 .bg-col .third .abtn1 {
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: var(--bgcolor);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  border: 1px solid var(--bgcolor);
}
.f-list-2 .bg-col .third .abtn1:hover {
  color: var(--bgcolor);
}

.f-list-2 .bg-col .third .abtn1:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.f-list-2 .bg-col .third .abtn1:hover:before {
  top: -30px;
  left: -30px;
}
.f-list-2 .bg-col .third .abtn1:active {
  color: #fff;
}
.f-list-2 .bg-col .third .abtn1:active:before {
  background: var(--bgcolor);
  transition: 0s;
}
.f-list-2 .bg-col .third .abtn2 {
  color: #fff;
  display: inline-block;
  position: relative;
  background-color: var(--para);
  border: 1px solid var(--para);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}
.f-list-2 .bg-col .third .abtn2:hover {
  color: var(--para);
}

.f-list-2 .bg-col .third .abtn2:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.f-list-2 .bg-col .third .abtn2:hover:before {
  top: -30px;
  left: -30px;
}
.f-list-2 .bg-col .third .abtn2:active {
  color: var(--bgcolor);
}
.f-list-2 .bg-col .third .abtn2:active:before {
  background: #fff;
  transition: 0s;
}
.f-list-2 .bg-col .third .abtn3 {
  padding: 1rem 0;
  width: 17rem;
  font-size: 12px;
  text-align: center;
  color: #fff;
  display: inline-block;
  position: relative;
  background-color: #345fb4;
  border: 1px solid #345fb4;
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}
.f-list-2 .bg-col .third .abtn3:hover {
  color: #345fb4;
}

.f-list-2 .bg-col .third .abtn3:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.f-list-2 .bg-col .third .abtn3:hover:before {
  top: -30px;
  left: -30px;
}
.f-list-2 .bg-col .third .abtn3:active {
  color: var(--bgcolor);
}
.f-list-2 .bg-col .third .abtn3:active:before {
  background: #fff;
  transition: 0s;
}
.f-list-2 .bg-col .third .abtn4 {
  padding: 1rem 0;
  width: 17rem;
  font-size: 12px;
  text-align: center;
  color: #fff;
  display: inline-block;
  position: relative;
  background-color: #e31414;
  border: 1px solid #e31414;
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}
.f-list-2 .bg-col .third .abtn4:hover {
  color: #e31414;
}

.f-list-2 .bg-col .third .abtn4:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.f-list-2 .bg-col .third .abtn4:hover:before {
  top: -30px;
  left: -30px;
}
.f-list-2 .bg-col .third .abtn4:active {
  color: var(--bgcolor);
}
.f-list-2 .bg-col .third .abtn4:active:before {
  background: #fff;
  transition: 0s;
}
.f-list-2 .bg-col .third ul {
  margin: 2rem 0;
}
.f-list-2 .bg-col .third li {
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 1rem !important;
}

.f-list-3 .bg-col {
  background-color: rgba(217, 217, 217, 0.6);
  border-radius: 10px;
  padding: 2rem 2rem 3rem;
}
.bg-col .heder-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.bg-col .heder-content h6 {
  font-size: 15px;
  font-weight: bold;
  color: #345fb4;
}
.bg-col .heder-content .a-btns {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.bg-col .heder-content .a-btns a {
  padding: 1rem 0;
  width: 17rem;
  font-size: 12px;
  text-align: center;
}
.bg-col .heder-content .a-btns a:first-child {
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: var(--para);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  border: 1px solid var(--para);
}
.bg-col .heder-content .a-btns a:first-child:hover {
  color: var(--para);
}

.bg-col .heder-content .a-btns a:first-child:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.bg-col .heder-content .a-btns a:first-child:hover:before {
  top: -30px;
  left: -30px;
}
.bg-col .heder-content .a-btns a:first-child:active {
  color: #fff;
}
.bg-col .heder-content .a-btns a:first-child:active:before {
  background: var(--bgcolor);
  transition: 0s;
}
.bg-col .heder-content .a-btns a:last-child {
  color: #fff;
  display: inline-block;
  position: relative;
  background-color: #345fb4;
  border: 1px solid #345fb4;
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}
.bg-col .heder-content .a-btns a:last-child:hover {
  color: #345fb4;
}

.bg-col .heder-content .a-btns a:last-child:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.bg-col .heder-content .a-btns a:last-child:hover:before {
  top: -30px;
  left: -30px;
}
.bg-col .heder-content .a-btns a:last-child:active {
  color: var(--bgcolor);
}
.bg-col .heder-content .a-btns a:last-child:active:before {
  background: #fff;
  transition: 0s;
}
.f-list-3 .bg-col .list-flex {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}
.f-list-3 .bg-col .list-flex .box .input {
  width: 100%;
  padding: 1rem;
  font-size: 1.8rem;
  margin: 1rem 0;
  min-height: 4rem;
  border-radius: 5px;
}
.f-list-3 .bg-col form .list-flex .box p {
  font-size: 12px;
  font-weight: 700;
  color: #8e8e8e;
}
.f-list-3 .bg-col form .list-flex .box .input::placeholder {
  color: #000;
  opacity: 1;
  font-size: 12px;
}
.f-list-3 .bg-col form .list-flex .box .input,
select::placeholder {
  color: #000;
  opacity: 1;
  font-size: 12px;
}
.f-list-3 .bg-col form .list-flex .box .subtitle {
  direction: rtl;
  display: grid;
  gap: 0.2rem;
}
.f-list-3 .bg-col form .list-flex .box .subtitle div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
.f-list-3 .bg-col form .list-flex .box .checkbox .bg-color {
  background-color: #cfcaca;
  display: inline-block;
  padding: 0.2rem 0.5rem;
}
.f-list-3 .bg-col form .list-flex .box .subtitle input {
  display: flex;
  appearance: none;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 20%;
  border: 1px solid #000;
}
.f-list-3 .bg-col form .list-flex .box .subtitle input::after {
  content: "✔";
  font-size: 1rem;
  margin: auto;
}
.f-list-3 .bg-col form .list-flex .box .subtitle input:not(:checked)::after {
  color: transparent;
  text-shadow: 0 0 0 transparent;
  transition: color 200ms ease-out, text-shadow 150ms ease-out 25ms;
}

.f-list-3 .bg-col form .list-flex .box .subtitle input:checked::after {
  color: #000;
  transition: color 200ms ease-in, text-shadow 150ms ease-in 25ms;
}

.f-list-3 .bg-col form .box.photos input {
  display: inline-block;
  width: 100%;
  margin: 1rem 0;
  padding: 29rem 0 0 0;
  height: 29rem;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url("./icons/arrow.svg") center center no-repeat #e4e4e4;
  border-radius: 5px;
  background-color: #fff;
}
.f-list-3 .bg-col form .box textarea {
  width: 100%;
  border-radius: 5px;
  height: 29rem;
  margin: 1rem 0;
}
.f-list-3 .bg-col form .box .subtitle .bg-color2 {
  text-align: right;
  color: #8e8e8e;
}
.f-list-3 .bg-col form .box.photos .subtitle .bg-color {
  color: #8e8e8e;
}
.f-list-3 .bg-col form .box p {
  font-size: 12px;
  font-weight: 700;
  color: #8e8e8e;
}
.f-list-3 .bg-col form .box .input {
  width: 100%;
  padding: 1rem;
  font-size: 1.8rem;
  margin: 1rem 0;
  min-height: 4rem;
  border-radius: 5px;
}
.f-list-3 .bg-col form .box .input,
select::placeholder {
  color: #000;
  opacity: 1;
  font-size: 12px;
}

.f-list-3 .bg-col form .box .subtitle .bg-color3 {
  text-align: right;
  color: #8e8e8e;
}
.f-list-3 .bg-col form .box.photos2 input {
  display: inline-block;
  width: 100%;
  margin-top: 1rem;
  padding: 4rem 0 0 0;
  max-height: 4rem;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url("./icons/arrow2.svg") center center no-repeat #e4e4e4;
  border-radius: 5px;
  background-color: #fff;
  background-position: right;
}
.f-list-3 .bg-col form .box.photos2 .subtitle .bg-color {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #8e8e8e;
}

.f-list-3 button {
  margin-top: 1rem;
  padding: 1rem 0;
  width: 17rem;
  font-size: 12px;
  text-align: center;
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: var(--para);
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  border: 1px solid var(--para);
}
.f-list-3 .bg-col form .box button:hover {
  color: var(--para);
}

.f-list-3 .bg-col form .box button:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.f-list-3 .bg-col form .box button:hover:before {
  top: -30px;
  left: -30px;
}
.f-list-3 .bg-col form .box button:active {
  color: #fff;
}
.f-list-3 .bg-col form .box button:active:before {
  background: #fff;
  transition: 0s;
}
.f-list-3 .bg-col form .box-content {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.f-list-3 .bg-col form .box-content div {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.f-list-3 .bg-col form .box-content div label {
  color: #8e8e8e;
  font-size: 12px;
  font-weight: 500;
}
.f-list-3 .bg-col form .box-content div input {
  display: flex;
  appearance: none;
  height: 2rem;
  width: 2rem;
  border-radius: 20%;
  border: 2px solid #000;
}
.f-list-3 .bg-col form .box-content div input::after {
  content: "✔";
  font-size: 1.4rem;
  margin: auto;
}

.f-list-3 .bg-col form .box-content div input:not(:checked)::after {
  color: transparent;
  text-shadow: 0 0 0 transparent;
  transition: color 200ms ease-out, text-shadow 150ms ease-out 25ms;
}

.f-list-3 .bg-col form .box-content div input:checked::after {
  color: #000;
  transition: color 200ms ease-in, text-shadow 150ms ease-in 25ms;
}
.f-list-3 .bg-col form .box-content div .btn-color1 {
  font-size: 10px;
  background-color: #f88a3b;
  width: 7rem;
  text-align: center;
  border-radius: 10px;
  color: #000;
  padding: 0;
}
.f-list-3 .bg-col form .box-content div .btn-color2 {
  font-size: 10px;
  background-color: #e0d03e;
  width: 7rem;
  text-align: center;
  border-radius: 10px;
  color: #000;
  padding: 0;
}
.f-list-3 .bg-col form .box-content h6 {
  color: #8e8e8e;
  font-size: 12px;
}

.f-list-4 .bg-col {
  background-color: rgba(217, 217, 217, 0.6);
  border-radius: 10px;
  padding: 2rem 2rem 3rem;
  margin: 2rem 0;
}
.f-list-4 .bg-col .checkbox {
  font-size: 12px;
  color: #8e8e8e;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.f-list-4 .bg-col .checkbox input {
  display: flex;
  appearance: none;
  height: 2rem;
  width: 2rem;
  border-radius: 20%;
  border: 2px solid #000;
}
.f-list-4 .bg-col .checkbox input::after {
  content: "✔";
  font-size: 1.4rem;
  margin: auto;
}

.f-list-4 .bg-col .checkbox input:not(:checked)::after {
  color: transparent;
  text-shadow: 0 0 0 transparent;
  transition: color 200ms ease-out, text-shadow 150ms ease-out 25ms;
}

.f-list-4 .bg-col .checkbox input:checked::after {
  color: #000;
  transition: color 200ms ease-in, text-shadow 150ms ease-in 25ms;
}
.f-list-4 .bg-col .checkbox label {
  width: 60%;
}
.f-list-4 .bg-col .box p {
  font-size: 12px;
  font-weight: 700;
  color: #8e8e8e;
}
.f-list-4 .bg-col .box .input {
  width: 100%;
  padding: 1rem;
  font-size: 1.8rem;
  margin: 1rem 0;
  max-height: 4rem;
  border-radius: 5px;
}
.f-list-4 .bg-col .small-box {
  font-size: 10px;
  width: 9rem;
  text-align: center;
  border-radius: 10px;
  background-color: #345fb4;
  color: #fff;
  margin: 1rem 0;
}
.f-list-4 .bg-col .subtitle {
  text-align: right;
}
.f-list-4 .bg-col .subtitle .sub-text {
  color: #8e8e8e;
}
.f-list-3 .bg-col form .headding {
  color: #8e8e8e;
  font-size: 12px;
}
.f-list-4 .bg-col form .box-flex {
  align-items: center;
}
.f-list-4 .bg-col form .box .input,
select::placeholder {
  color: #000;
  opacity: 1;
  font-size: 12px;
}
.f-list-4 .bg-col form .box-gap {
  margin-top: 3.8rem;
}
.f-list-4 .bg-col .box-flex p {
  font-size: 12px;
  font-weight: 700;
  color: #8e8e8e;
}
.f-list-4 .bg-col .box-flex .checkbox input {
  display: flex;
  appearance: none;
  height: 2rem;
  width: 2rem;
  border-radius: 20%;
  border: 2px solid #000;
}
.f-list-4 .bg-col .box-flex .checkbox input::after {
  content: "✔";
  font-size: 1.4rem;
  margin: auto;
}

.f-list-4 .bg-col .box-flex .checkbox input:not(:checked)::after {
  color: transparent;
  text-shadow: 0 0 0 transparent;
  transition: color 200ms ease-out, text-shadow 150ms ease-out 25ms;
}

.f-list-4 .bg-col .box-flex .checkbox input:checked::after {
  color: #000;
  transition: color 200ms ease-in, text-shadow 150ms ease-in 25ms;
}
.f-list-4 .bg-col .box-flex .checkbox .day-time {
  font-size: 14px;
  color: #000;
  font-weight: 400;
}
.f-list-4 .bg-col form .second-titel {
  font-size: 12px;
  color: #8e8e8e;
  text-align: center;
  margin-top: 2rem;
}

/* freelancer list end 
===========================*/

/* sign-up and login start 
===========================*/

/* slider start 
===========================*/

.iner-slider .slider-1 .video {
  width: 100%;
  height: 30vh;
  object-fit: cover;
}

.iner-slider .carousel-caption .title {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  width: 53%;
  margin: 0 auto;
}

/* slider end 
===========================*/
.sign-log {
  padding: 3rem 0;
}
.sign-log .box-center {
  /* display: flex;
    align-items: center;
    justify-content: center; */
  width: min(100% - 30px, 570px);
  margin-inline: auto;
}
.sign-log .box-center .box-bg {
  background-color: rgba(217, 217, 217, 0.6);
  padding: 3rem;
  border-radius: 5px;
}
.sign-log .box-center .box-bg h3 {
  font-size: 20px;
  color: var(--bgcolor);
  text-transform: uppercase;
  margin-bottom: 2rem;
  text-align: center;
}
.sign-log .box-center .box-bg .list-flex {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}
.sign-log .box-center .list-flex p {
  font-size: 12px;
  font-weight: 700;
  color: #8e8e8e;
}
.sign-log .box-center .list-flex input {
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
  min-height: 4rem;
  border-radius: 5px;
}
.sign-log .box-center .check-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.sign-log .box-center .check-btn input,
.sign-log .box-center .btn-flex input {
  appearance: none;
  height: 2rem;
  width: 2rem;
  border-radius: 20%;
  border: 2px solid #000;
}
.sign-log .box-center .check-btn input::after,
.sign-log .box-center .btn-flex input::after {
  content: "✔";
  font-size: 1.3rem;
  padding-left: 0.2rem;
}

.sign-log .box-center .check-btn input:not(:checked)::after,
.sign-log .box-center .btn-flex input:not(:checked)::after {
  color: transparent;
  text-shadow: 0 0 0 transparent;
  transition: color 200ms ease-out, text-shadow 150ms ease-out 25ms;
}

.sign-log .box-center .check-btn input:checked::after,
.sign-log .box-center .btn-flex input:checked::after {
  color: #000;
  transition: color 200ms ease-in, text-shadow 150ms ease-in 25ms;
}
.sign-log .box-center .check-btn label {
  font-size: 15px;
  font-weight: bold;
  color: #8e8e8e;
}
.sign-log .box-center .check-btn label a {
  color: #345fb4;
}
.sign-log .box-center .btn-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 6.7rem;
  justify-content: center;
  margin: 2rem 0;
}
.sign-log .box-center .btn-flex div {
  display: flex;
  gap: 1rem;
}
.sign-log .box-center .btn-flex label {
  font-size: 15px;
  font-weight: bold;
  color: #8e8e8e;
}

.sign-log .box-center form button {
  margin-top: 1rem;
  padding: 1rem 0;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: #345fb4;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  border: 1px solid #345fb4;
}
.sign-log .box-center form button:hover {
  color: #345fb4;
}

.sign-log .box-center form button:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 190px;
  width: 600px;
  border-radius: 50%;
  z-index: -1;
}
.sign-log .box-center form button:hover:before {
  top: -50px;
  left: -40px;
}
.sign-log .box-center form button:active {
  color: #345fb4;
}
.sign-log .box-center form button:active:before {
  background: #fff;
  transition: 0s;
}
.sign-log .box-center form .title {
  font-size: 15px;
  font-weight: bold;
  color: #8e8e8e;
  text-align: center;
  margin-top: 2rem;
}
.sign-log .box-center form .title a {
  color: var(--bgcolor);
}
/* sign-up end 
=======================*/
/* login start 
=======================*/
.sign-log .box-center .login-list-flex p {
  font-size: 12px;
  font-weight: 700;
  color: #8e8e8e;
}
.sign-log .box-center .login-list-flex .input {
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
  min-height: 4rem;
  border-radius: 5px;
}
.sign-log .box-center .login-btn-flex {
  display: grid;
  justify-content: center;
  margin: 2rem 0;
}
.sign-log .box-center .login-btn-flex div {
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
}
.sign-log .box-center .login-btn-flex input {
  appearance: none;
  height: 2rem;
  width: 2rem;
  border-radius: 20%;
  border: 2px solid #000;
}
.sign-log .box-center .login-btn-flex input::after {
  content: "✔";
  font-size: 1.3rem;
  padding-left: 0.2rem;
}

.sign-log .box-center .login-btn-flex input:not(:checked)::after {
  color: transparent;
  text-shadow: 0 0 0 transparent;
  transition: color 200ms ease-out, text-shadow 150ms ease-out 25ms;
}

.sign-log .box-center .login-btn-flex input:checked::after {
  color: #000;
  transition: color 200ms ease-in, text-shadow 150ms ease-in 25ms;
}
.sign-log .box-center .login-btn-flex label {
  font-size: 15px;
  font-weight: bold;
  color: #8e8e8e;
}
.sign-log .box-center .login-btn-flex a {
  font-size: 15px;
  font-weight: bold;
  color: var(--bgcolor);
  text-align: center;
  margin-top: 2rem;
}
.sign-log .box-center form .log-title {
  font-size: 12px;
  color: #8e8e8e;
  text-align: right;
  margin: 2rem 0;
}
/* login end ]]
=======================*/
/* sign-up and login end 
===========================*/

/* contact start
=========================== */
.sign-log .box-center .login-list-flex textarea {
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 5px;
}
.sign-log .box-center .cont-btn-flex label {
  font-size: 15px;
  font-weight: bold;
  color: #8e8e8e;
}
.sign-log .box-center .cont-btn-flex {
  display: flex;
  align-items: center;
  margin: 2rem 0;
}
.sign-log .box-center .cont-btn-flex div {
  width: 50%;
  display: flex;
  gap: 1rem;
}
.sign-log .box-center .cont-btn-flex div input {
  display: flex;
  appearance: none;
  height: 2rem;
  width: 2rem;
  border-radius: 20%;
  border: 2px solid #000;
}
.sign-log .box-center .cont-btn-flex div input::after {
  content: "✔";
  font-size: 1.4rem;
  margin: auto;
}

.sign-log .box-center .cont-btn-flex div input:not(:checked)::after {
  color: transparent;
  text-shadow: 0 0 0 transparent;
  transition: color 200ms ease-out, text-shadow 150ms ease-out 25ms;
}

.sign-log .box-center .cont-btn-flex div input:checked::after {
  color: #000;
  transition: color 200ms ease-in, text-shadow 150ms ease-in 25ms;
}
.sign-log .box-center form .cont-title {
  font-size: 12px;
  color: #8e8e8e;
  margin: 2rem 0;
}
.sign-log .box-center .headding {
  font-size: 20px;
  font-weight: 600;
  color: var(--bgcolor);
  text-align: center;
  margin-bottom: 2rem;
}
.sign-log .box-center .headding a {
  color: #b90e0e;
}
.sign-log .box-center .login-list-flex .box .subtitle {
  text-align: right;
}
.sign-log .box-center .login-list-flex .box .subtitle label {
  color: #8e8e8e;
  font-size: 12px;
  font-weight: 600;
}
.sign-log .box-center .login-list-flex .box .subtitle .icon {
  margin: 1rem 0;
}

/* contact end
=========================== */

/* invite */

/* about start
=========================== */

.about {
  padding: 3rem 0;
}

.about .box {
  padding: 3rem 0;
  text-align: center;
}
.about .box h6 {
  font-size: 18px;
  font-weight: bold;
  margin: 1rem;
  text-transform: uppercase;
  text-align: center;
}
.about .box p {
  font-size: 12px;
  margin: 1rem 2rem;
  color: #444444;
}
.about2 {
  padding: 6rem;
  background-color: rgba(14, 113, 185, 0.1);
}
.about2 img {
  width: 100%;
  border-radius: 10px;
}
.about2 p {
  font-size: 1.5rem;
  width: 87%;
}
.about2 .titel {
  padding: 6rem 0;
}
/* about end
=========================== */

/* vacancies start
=========================== */

.vacan-1 {
  padding-bottom: 2rem;
}
.vacan-1 .bg-col {
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  padding: 3rem 2rem;
}
.vacan-1 .head {
  font-weight: bold;
  font-size: 15px;
  color: #345fb4;
  padding-left: 2rem;
  margin-bottom: 1rem;
}
.vacan-1 .date {
  font-weight: bold;
  font-size: 15px;
  color: #8e8e8e;
}

.vacan-1 .bg-col h3 {
  font-size: 16px;
  color: var(--bgcolor);
  font-weight: bold;
  padding: 1rem 0;
}
.vacan-1 .bg-col h3 img {
  -ms-transform: rotate(180deg); /* IE 9 */
  transform: rotate(180deg);
}
.vacan-1 .bg-col p {
  font-size: 12px;
  color: var(--para);
  margin-bottom: 2rem;
}
.vacan-1 .bg-col .box-aline {
  margin: 0 !important;
  padding-left: 1rem;
}

.vacan-1 .bg-col .box {
  border-right: 2px solid #d9d9d9;
}
.vacan-1 .bg-col .box div {
  margin: 2rem 0 0;
}
.vacan-1 .bg-col .box .abtn1 {
  padding: 1rem 0;
  width: 94%;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
  text-align: center;
}
.vacan-1 .bg-col .box .abtn1 {
  display: inline-block;
  position: relative;
  background-color: var(--para);
  border: 1px solid var(--para);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  text-transform: uppercase;
}
.vacan-1 .bg-col .box .abtn1:hover {
  color: var(--para);
}

.vacan-1 .bg-col .box .abtn1:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.vacan-1 .bg-col .box .abtn1:hover:before {
  top: -30px;
  left: -30px;
}
.vacan-1 .bg-col .box .abtn1:active {
  color: var(--bgcolor);
}
.vacan-1 .bg-col .box .abtn1:active:before {
  background: #fff;
  transition: 0s;
}
.vacan-1 .bg-col .box-flex {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.vacan-1 .bg-col .box .abtn2 {
  font-size: 10px;
  background-color: #f88a3b;
  width: 7rem;
  text-align: center;
  border-radius: 10px;
  color: #000;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
}
.vacan-1 .bg-col .box .abtn3 {
  font-size: 10px;
  background-color: #e0d03e;
  width: 7rem;
  text-align: center;
  border-radius: 10px;
  color: #000;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
}
.vacan-1 .list-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.vacan-1 .abtn a {
  padding: 1rem 0;
  margin-bottom: 1rem;
  width: 17rem;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: var(--bgcolor);
  border: 1px solid var(--bgcolor);
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  text-transform: uppercase;
  text-align: center;
}
.vacan-1 .abtn a:hover {
  color: var(--bgcolor);
}

.vacan-1 .abtn a:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.vacan-1 .abtn a:hover:before {
  top: -30px;
  left: -30px;
}
.vacan-1 .abtn a:active {
  color: var(--bgcolor);
}
.vacan-1 .abtn a:active:before {
  background: #fff;
  transition: 0s;
}

.vacan-2 .bg-color {
  background-color: rgba(217, 217, 217, 0.6);
  border-radius: 10px;
  padding: 2rem 2rem 3rem;
}
.vacan-2 .bg-color .box-flex {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.vacan-2 .bg-color .box-flex h6 {
  font-size: 15px;
  font-weight: bold;
  color: #345fb4;
}
.vacan-2 .bg-color .box-flex .btn {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.vacan-2 .bg-color .box-flex .btn .btn1 {
  padding: 1rem 0;
  width: 17rem;
  font-size: 12px;
  text-align: center;
  color: #fff;
  display: inline-block;
  position: relative;
  background-color: #e31414;
  border: 1px solid #e31414;
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}
.vacan-2 .bg-color .box-flex .btn .btn1:hover {
  color: #e31414;
}

.vacan-2 .bg-color .box-flex .btn .btn1:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.vacan-2 .bg-color .box-flex .btn .btn1:hover:before {
  top: -30px;
  left: -30px;
}
.vacan-2 .bg-color .box-flex .btn .btn1:active {
  color: #e31414;
}
.vacan-2 .bg-color .box-flex .btn .btn1:active:before {
  background: #fff;
  transition: 0s;
}

.vacan-2 .bg-color .box-flex .btn .btn2 {
  padding: 1rem 0;
  width: 17rem;
  font-size: 12px;
  text-align: center;
  color: #fff;
  display: inline-block;
  position: relative;
  background-color: var(--para);
  border: 1px solid var(--para);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}
.vacan-2 .bg-color .box-flex .btn .btn2:hover {
  color: var(--para);
}

.vacan-2 .bg-color .box-flex .btn .btn2:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.vacan-2 .bg-color .box-flex .btn .btn2:hover:before {
  top: -30px;
  left: -30px;
}
.vacan-2 .bg-color .box-flex .btn .btn2:active {
  color: var(--para);
}
.vacan-2 .bg-color .box-flex .btn .btn2:active:before {
  background: #fff;
  transition: 0s;
}

.vacan-2 .bg-color .box-flex .btn .btn3 {
  padding: 1rem 0;
  width: 17rem;
  font-size: 12px;
  text-align: center;
  color: #fff;
  display: inline-block;
  position: relative;
  background-color: var(--bgcolor);
  border: 1px solid var(--bgcolor);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}
.vacan-2 .bg-color .box-flex .btn .btn3:hover {
  color: var(--bgcolor);
}

.vacan-2 .bg-color .box-flex .btn .btn3:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.vacan-2 .bg-color .box-flex .btn .btn3:hover:before {
  top: -30px;
  left: -30px;
}
.vacan-2 .bg-color .box-flex .btn .btn3:active {
  color: var(--bgcolor);
}
.vacan-2 .bg-color .box-flex .btn .btn3:active:before {
  background: #fff;
  transition: 0s;
}
.vacan-2 .bg-color .box .input,
.vacan-2 .bg-color .box select {
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
  min-height: 4rem;
  border-radius: 5px;
  font-size: 12px;
}
.vacan-2 .bg-color .box p {
  font-size: 12px;
  font-weight: 700;
  color: #8e8e8e;
}
.vacan-2 .bg-color .box .subtitle {
  text-align: right;
}
.vacan-2 .bg-color .box .subtitle .titel-color {
  color: #8e8e8e;
  margin-top: -11px;
}

.vacan-2 .bg-color .box.photos input {
  display: inline-block;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 4rem 0 0 0;
  max-height: 4rem;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url("./icons/arrow2.svg") center center no-repeat #e4e4e4;
  border-radius: 5px;
  background-color: #fff;
  background-position: right;
}

.vacan-2 .bg-color .box-flex2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  margin-bottom: 2rem;
  gap: 2rem;
}
.vacan-2 .bg-color .box-flex2 .box-col {
  padding-right: 2rem;
  border-right: 2px solid #d9d9d9;
}
.vacan-2 .bg-color .box-flex2 h6 {
  font-size: 15px;
  font-weight: bold;
  color: #345fb4;
  margin: 2rem 0;
}

.vacan-2 .bg-color .box-flex2 .box .subtitle {
  direction: rtl;
  display: grid;
  gap: 0.2rem;
}
.vacan-2 .bg-color .box-flex2 .box .subtitle div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.vacan-2 .bg-color .box-flex2 .box .subtitle label {
  color: #8e8e8e;
  font-size: 8px;
  font-weight: 600;
}
.vacan-2 .bg-color .box-flex2 .box .subtitle input {
  display: flex;
  appearance: none;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 20%;
  border: 1px solid #000;
}
.vacan-2 .bg-color .box-flex2 .box .subtitle input::after {
  content: "✔";
  font-size: 1rem;
  margin: auto;
}
.vacan-2 .bg-color .box-flex2 .box .subtitle input:not(:checked)::after {
  color: transparent;
  text-shadow: 0 0 0 transparent;
  transition: color 200ms ease-out, text-shadow 150ms ease-out 25ms;
}

.vacan-2 .bg-color .box-flex2 .box .subtitle input:checked::after {
  color: #000;
  transition: color 200ms ease-in, text-shadow 150ms ease-in 25ms;
}

.vacan-2 .bg-color form .box-content {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.vacan-2 .bg-color form .box-content div {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.vacan-2 .bg-color form .box-content div label {
  color: #8e8e8e;
  font-size: 12px;
  font-weight: 500;
}
.vacan-2 .bg-color form .box-content div input {
  display: flex;
  appearance: none;
  height: 2rem;
  width: 2rem;
  border-radius: 20%;
  border: 2px solid #000;
}
.vacan-2 .bg-color form .box-content div input::after {
  content: "✔";
  font-size: 1.4rem;
  margin: auto;
}

.vacan-2 .bg-color form .box-content div input:not(:checked)::after {
  color: transparent;
  text-shadow: 0 0 0 transparent;
  transition: color 200ms ease-out, text-shadow 150ms ease-out 25ms;
}

.vacan-2 .bg-color form .box-content div input:checked::after {
  color: #000;
  transition: color 200ms ease-in, text-shadow 150ms ease-in 25ms;
}
.vacan-2 .bg-color form .box-content div .btn-color1 {
  font-size: 10px;
  background-color: #f88a3b;
  width: 7rem;
  text-align: center;
  border-radius: 10px;
  color: #000;
  padding: 0;
}
.vacan-2 .bg-color form .box-content div .btn-color2 {
  font-size: 10px;
  background-color: #e0d03e;
  width: 7rem;
  text-align: center;
  border-radius: 10px;
  color: #000;
  padding: 0;
}
.vacan-2 .bg-color form .box-content h6 {
  color: #8e8e8e;
  font-size: 12px;
}
.vacan-2 .bg-color .title-requ {
  color: #8e8e8e;
  font-size: 12px;
  text-align: right;
}

.f-list-1 .bg-col .head-top {
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.f-list-1 .bg-col .head-top a {
  padding: 1rem 0;
  width: 17rem;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
}
.f-list-1 .bg-col .head-top .abtn3 {
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: var(--bgcolor);
  border: 1px solid var(--bgcolor);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  text-transform: uppercase;
}
.f-list-1 .bg-col .head-top .abtn3:hover {
  color: var(--bgcolor);
}

.f-list-1 .bg-col .head-top .abtn3:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.f-list-1 .bg-col .head-top .abtn3:hover:before {
  top: -30px;
  left: -30px;
}
.f-list-1 .bg-col .head-top .abtn3:active {
  color: var(--bgcolor);
}
.f-list-1 .bg-col .head-top .abtn3:active:before {
  background: #fff;
  transition: 0s;
}

.vacan-3 {
  padding-bottom: 2rem;
}
.vacan-3 .bg-color {
  background-color: rgba(217, 217, 217, 0.6);
  border-radius: 10px;
  padding: 4rem 2rem;
}
.vacan-3 .bg-color .abtns a {
  padding: 1rem 0;
  width: 100%;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  position: relative;
  background-color: var(--bgcolor);
  border: 1px solid var(--bgcolor);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}
.vacan-3 .bg-color .abtns a:hover {
  color: var(--bgcolor);
}

.vacan-3 .bg-color .abtns a:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 190px;
  width: 600px;
  border-radius: 50%;
  z-index: -1;
}
.vacan-3 .bg-color .abtns a:hover:before {
  top: -50px;
  left: -40px;
}
.vacan-3 .bg-color .abtns a:active {
  color: var(--bgcolor);
}
.vacan-3 .bg-color .abtns a:active:before {
  background: #fff;
  transition: 0s;
}
.vacan-3 .bg-color .abtns p {
  color: #8e8e8e;
  font-size: 10px;
}

.vacan-4 {
  padding-bottom: 2rem;
}
.vacan-4 .bg-color {
  background-color: rgba(217, 217, 217, 0.6);
  border-radius: 10px;
  padding: 4rem 2rem;
}
.vacan-4 .bg-color .box .input {
  width: 100%;
  padding: 1rem;
  font-size: 1.8rem;
  margin: 1rem 0;
  max-height: 4rem;
  border-radius: 5px;
}
.vacan-4 .bg-color form .box .input {
  color: #000;
  opacity: 1;
  font-size: 12px;
}
.vacan-4 .bg-color h6 {
  font-size: 15px;
  font-weight: bold;
  color: #345fb4;
}
.vacan-4 .bg-color p {
  font-size: 12px;
  font-weight: 700;
  color: #8e8e8e;
}

.vacan-4 .bg-color .head-top {
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.vacan-4 .bg-color .head-top a {
  padding: 1rem 0;
  width: 17rem;
  font-size: 12px;
  color: #fff;
  border-radius: 10px;
}
.vacan-4 .bg-color .head-top .abtn3 {
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: var(--bgcolor);
  border: 1px solid var(--bgcolor);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  text-transform: uppercase;
}
.vacan-4 .bg-color .head-top .abtn3:hover {
  color: var(--bgcolor);
}

.vacan-4 .bg-color .head-top .abtn3:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.vacan-4 .bg-color .head-top .abtn3:hover:before {
  top: -30px;
  left: -30px;
}
.vacan-4 .bg-color .head-top .abtn3:active {
  color: var(--bgcolor);
}
.vacan-4 .bg-color .head-top .abtn3:active:before {
  background: #fff;
  transition: 0s;
}

.vacan-4 .bg-color form .box .checkbox .bg-color {
  background-color: #cfcaca;
  display: inline-block;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  float: right;
}

/* vacancies end
=========================== */

/* company start
=========================== */

.company1 {
  margin-bottom: 2rem;
}

.f-list-2 .bg-col .third .list-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.f-list-2 .bg-col .third .comp-abtn1 {
  width: 10rem;
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: var(--bgcolor);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  border: 1px solid var(--bgcolor);
}
.f-list-2 .bg-col .third .comp-abtn1:hover {
  color: var(--bgcolor);
}

.f-list-2 .bg-col .third .comp-abtn1:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.f-list-2 .bg-col .third .comp-abtn1:hover:before {
  top: -30px;
  left: -30px;
}
.f-list-2 .bg-col .third .comp-abtn1:active {
  color: #fff;
}
.f-list-2 .bg-col .third .comp-abtn1:active:before {
  background: var(--bgcolor);
  transition: 0s;
}
.f-list-2 .bg-col .third .comp-abtn2 {
  width: 10rem;
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: var(--para);
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  border: 1px solid var(--para);
}
.f-list-2 .bg-col .third .comp-abtn2:hover {
  color: var(--para);
}

.f-list-2 .bg-col .third .comp-abtn2:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.f-list-2 .bg-col .third .comp-abtn2:hover:before {
  top: -30px;
  left: -30px;
}
.f-list-2 .bg-col .third .comp-abtn2:active {
  color: #fff;
}
.f-list-2 .bg-col .third .comp-abtn2:active:before {
  background: var(--para);
  transition: 0s;
}

.f-list-2 .bg-col .third .comp-abtn3 {
  width: 10rem;
  display: inline-block;
  position: relative;
  color: #fff;
  background-color: #d9d9d9;
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
  border: 1px solid #d9d9d9;
}
.f-list-2 .bg-col .third .comp-abtn3:hover {
  color: #d9d9d9;
}

.f-list-2 .bg-col .third .comp-abtn3:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 150px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.f-list-2 .bg-col .third .comp-abtn3:hover:before {
  top: -30px;
  left: -30px;
}
.f-list-2 .bg-col .third .comp-abtn3:active {
  color: #fff;
}
.f-list-2 .bg-col .third .comp-abtn3:active:before {
  background: #d9d9d9;
  transition: 0s;
}

.f-list-2 .bg-col .third table {
  width: 100%;
}
.f-list-2 .bg-col .third table td {
  color: #000;
  font-size: 12px;
  padding-bottom: 1rem;
}

.company1 .bg-color {
  background-color: rgba(217, 217, 217, 0.6);
  border-radius: 10px;
  padding: 2rem 2rem 3rem;
}
.company1 .bg-color .head {
  color: #345fb4;
  font-size: 15px;
  margin-bottom: 1rem;
}
.company1 .bg-color .title-requ {
  color: #8e8e8e;
  font-size: 12px;
  text-align: right;
  margin-bottom: 1rem;
}
.company1 .bg-color form {
  margin-top: 1rem;
}

.company1 .box p {
  font-size: 12px;
  font-weight: 700;
  color: #8e8e8e;
}
.company1 .box .input {
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
  min-height: 4rem;
  border-radius: 5px;
  color: #000;
  opacity: 1;
  font-size: 12px;
}
.company1 .box .checkbox .bg-color {
  background-color: #cfcaca;
  display: inline-block;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  float: right;
}
.company1 .box textarea {
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  height: 11.7rem;
  margin: 1rem 0;
}
.company1 .box .subtitle {
  text-align: right;
}
.company1 .box .sub-text {
  color: #8e8e8e;
  font-size: 10px;
}
.company1 .bg-color form .a-btn1 {
  padding: 2rem 3.3rem;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  display: inline-block;
  position: relative;
  background-color: #345fb4;
  border: 1px solid #345fb4;
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}
.company1 .bg-color form .a-btn1:hover {
  color: #345fb4;
}

.company1 .bg-color form .a-btn1:before {
  top: 100%;
  left: 100%;
  transition: 0.7s;
  content: "";
  position: absolute;
  background: #fff;
  height: 190px;
  width: 245px;
  border-radius: 50%;
  z-index: -1;
}
.company1 .bg-color form .a-btn1:hover:before {
  top: -30px;
  left: -40px;
}
.company1 .bg-color form .a-btn1:active {
  color: var(--bgcolor);
}
.company1 .bg-color form .a-btn1:active:before {
  background: #fff;
  transition: 0s;
}
.company1 .bg-color form li:first-child {
  padding-left: 0 !important;
}
.company1 .bg-color form li {
  font-size: 12px;
  font-weight: bold;
  color: #8e8e8e;
  padding-left: 4.2rem !important;
  margin-bottom: 1rem;
}
.company1 .bg-color form .list-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  font-size: 12px;
  color: #8e8e8e;
  font-weight: 600;
}
.company1 .bg-color form .position {
  padding: 0 3rem 0 4.2rem;
}
.company1 .bg-color form .position .checkbox {
  font-size: 12px;
  color: #8e8e8e;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.company1 .bg-color form .position .checkbox input {
  display: flex;
  appearance: none;
  height: 2rem;
  width: 2rem;
  border-radius: 20%;
  border: 2px solid #000;
}
.company1 .bg-color form .position .checkbox input::after {
  content: "✔";
  font-size: 1.4rem;
  margin: auto;
}

.company1 .bg-color form .position .checkbox input:not(:checked)::after {
  color: transparent;
  text-shadow: 0 0 0 transparent;
  transition: color 200ms ease-out, text-shadow 150ms ease-out 25ms;
}

.company1 .bg-color form .position .checkbox input:checked::after {
  color: #000;
  transition: color 200ms ease-in, text-shadow 150ms ease-in 25ms;
}

.company1 .bg-color form .list-flex .box-flex {
  flex: 1 1 10rem;
}

.company1 .bg-color .title-tag {
  display: inline-block;
  float: right;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
  padding: 0.2rem 0.7rem;
  background-color: #345fb4;
  border-radius: 5px;
}
.company1 .bg-color .table {
  width: 100%;
  margin: 3rem 0;
  border-radius: 10px;
  overflow: hidden;
}
.company1 .bg-color .table th {
  background-color: #e5c3ff;
  text-align: center;
}

.company1 .bg-color .table th,
.company1 .bg-color .table td {
  border-right: 2px solid #fff;
  padding: 8px;
  font-size: 12px;
}
.company1 .bg-color .table tr:nth-child(even) {
  background-color: #fff;
}
.company1 .bg-color .table tr:nth-child(odd) {
  background-color: #e7ffc8;
}
.company1 .bg-color .table tr td:first-child {
  color: #4aa8ff;
}
.company1 .bg-color .table tr td:last-child,
.company1 .bg-color .table th:last-child {
  border: none;
}
/* .company1 .bg-color .table th:last-child{
    width: 11%;
} */
