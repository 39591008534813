@media (max-width: 1199px) {
  /* home start */
  .carousel-caption {
    top: 9%;
  }

  /* home end */

  /* freelancer list start */
  .f-list-1 .bg-col form .list-flex {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  }
  .f-list-2 .bg-col .third a {
    width: 100%;
  }
  .f-list-2 .bg-col .third .abtn3 {
    width: 100%;
  }
  .f-list-3 .bg-col form .box button {
    width: 100%;
  }
  .f-list-3 .bg-col form .box-content {
    gap: 1rem;
  }
  .f-list-2 .bg-col .third .abtn4 {
    width: 100%;
  }

  /* freelancer list end */

  /* company start */

  .f-list-2 .bg-col .third .comp-abtn1,
  .f-list-2 .bg-col .third .comp-abtn2,
  .f-list-2 .bg-col .third .comp-abtn3 {
    width: 8rem;
  }

  /* company end */
}

@media (max-width: 991px) {
  .nav a {
    margin-right: 1rem;
  }
  /* home start */
  .carousel-caption .heading {
    width: 100%;
  }
  .carousel-caption .title {
    width: 100%;
  }
  .section3 .cover-img .heading {
    width: 100%;
  }
  .section3 .cover-img .title {
    width: 100%;
  }

  /* home end */

  /* freelancer list start */
  .f-list-2 .bg-col .second .border-line {
    border: none;
  }
  .f-list-3 .bg-col form .box-content {
    margin-top: 1rem;
  }
  .f-list-4 .bg-col .checkbox label {
    width: 100%;
  }
  .f-list-4 .bg-col form .box-gap {
    margin: 0;
  }
  .f-list-4 .bg-col form .second-titel {
    text-align: left;
  }

  /* freelancer list end */

  /* vacancies start */

  .vacan-1 .bg-col .box:nth-child(2) {
    border: none;
  }
  .vacan-2 .bg-color .box-flex2 .box-col {
    border: none;
    padding: 0;
  }

  /* vacancies end */

  /* company start */

  .f-list-2 .bg-col .third .comp-abtn1,
  .f-list-2 .bg-col .third .comp-abtn2,
  .f-list-2 .bg-col .third .comp-abtn3 {
    width: 12rem;
  }
  .f-list-2 .bg-col .third .list-flex {
    margin-bottom: 2rem;
  }

  .company1 .bg-color form .position {
    margin-bottom: 2rem;
  }

  .company1 .bg-color .table {
    width: 100%;
    margin: 3rem 0;
    border-radius: 10px;
    overflow: scroll;
    display: block;
    height: 400px;
  }
  .company1 .bg-color .table th {
    background-color: #e5c3ff;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  /* company end */
}

@media (max-width: 768px) {
  #menu-btn {
    display: block;
  }
  .nav .menu-box i {
    float: right;
  }
  .nav .menu-box .navbar-main {
    position: absolute;
    top: 100%;
    left: -100%;
    background-color: #04225e;
    width: 20rem;
    height: calc(100vh - 5.5rem);
    overflow: scroll;
  }
  .nav .menu-box .navbar-main::-webkit-scrollbar {
    height: 0.5rem;
    width: 1rem;
  }

  .nav .menu-box .navbar-main::-webkit-scrollbar-track {
    background-color: #04225e;
  }

  .nav .menu-box .navbar-main::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 5rem;
  }

  .nav .menu-box .navbar-main.active {
    left: 0;
  }
  .nav .menu-box .navbar-main a {
    color: #fff;
    display: block;
    margin: 2rem 3rem 0 3rem;
    padding: 0.5rem;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
  }

  .carousel-caption .heading {
    font-size: 36px;
  }
  .section2 .heading {
    font-size: 36px;
  }
  .section2 h3 {
    font-size: 26px;
  }
  .section-add .back-img {
    gap: 2.5rem;
  }
  .section3 .cover-img .heading {
    font-size: 36px;
  }
  .section4 .heading {
    font-size: 36px;
    margin-top: 0;
  }
  .footer .box {
    margin-top: 1.5rem;
  }

  /* freelancer list start */

  .f-list-2 .bg-col .third a,
  .f-list-2 .bg-col .third .abtn3,
  .f-list-2 .bg-col .third .abtn4 {
    width: 17rem;
  }

  /* freelancer list end */

  /* vacancies start */

  .vacan-2 .bg-color .box-flex2 .box-col {
    border: none;
    padding: 0;
  }

  .vacan-2 .bg-color .box-flex2 {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }

  /* vacancies end */

  /* about start */

  .about2 .titel {
    padding: 3rem 0;
  }
  .about2 p {
    width: 100%;
  }

  /* about end */
}

@media (max-width: 574px) {
  /* freelancer list start */
  .bg-col .heder-content h6 {
    margin-bottom: 1rem;
  }
  .f-list-1 .bg-col form .list-flex {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
  .f-list-3 .bg-col .list-flex {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
  .f-list-2 .bg-col .third a,
  .f-list-2 .bg-col .third .abtn3,
  .f-list-2 .bg-col .third .abtn4 {
    width: 17rem;
  }
  /* freelancer list end */

  /* vacancies start */

  .vacan-1 .bg-col .box .abtn1 {
    width: 17rem;
  }
  .vacan-1 .bg-col .box {
    border: none;
  }

  .vacan-2 .bg-color .box-flex .btn .btn1,
  .vacan-2 .bg-color .box-flex .btn .btn2,
  .vacan-2 .bg-color .box-flex .btn .btn3 {
    width: 12rem;
  }
  .vacan-2 .bg-color .box-flex2 .box .subtitle div {
    justify-content: left;
    gap: 2rem;
  }
  .vacan-2 .bg-color .title-requ {
    margin-top: 2rem;
  }
  .vacan-3 .bg-color .abtns a {
    margin-top: 1rem;
  }
  .vacan-1 .bg-col .box-aline {
    padding: 0;
  }
  .vacan-1 .date {
    text-align: center;
  }

  /* vacancies end */

  /* about start  */

  .about2 {
    padding: 4rem 0;
  }

  /* about end */

  /* company start */

  .f-list-2 .bg-col .third .list-flex {
    flex-wrap: wrap;
  }

  .company1 .bg-color form .a-btn1 {
    margin-bottom: 1rem;
  }
  .company1 .bg-color form .a-btn1:hover {
    color: #345fb4;
  }

  .company1 .bg-color form .a-btn1:before {
    width: 505px;
  }
  .company1 .bg-color form .a-btn1:hover:before {
    top: -50px;
    left: -60px;
  }

  /* company end */
}

@media (max-width: 450px) {
  /* freelancer list start */

  .f-list-2 .bg-col .third a,
  .f-list-2 .bg-col .third .abtn3,
  .f-list-2 .bg-col .third .abtn4 {
    width: 100%;
  }

  /* freelancer list end */

  /* vacancies start */

  .vacan-2 .bg-color .box-flex {
    flex-wrap: wrap;
  }

  /* vacancies end */
}
