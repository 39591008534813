.profileLink {
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.profileLink:hover {
  color: white;
  background-color: #345fb4;
}

.LinkButtonReact {
  background-color: #0070c0;
  color: white;
  padding: 2rem;
  border-radius: 1rem;
  padding-inline: 3rem;
  font-size: 2rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.LinkButtonReact:hover {
  transform: scale(0.98);
  color: white;
  /* background-color: white; */
}

.LinkButtonReact:active {
  color: white;
}
.LinkButtonReact:active:before {
  background: #fff;
  transition: 0s;
}

.dropShadow {
  border: 1px solid black;
}

.hidetext {
  position: relative;
  display: flex;
}

.insideTooltip {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  /* top: -3rem; */
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
}

.hidetext:hover .insideTooltip {
  visibility: visible;
}

input:disabled {
  background-color: #cacaca;
}
select:disabled {
  background-color: #cacaca;
}

.hoverParent {
  cursor: pointer;
  font-size: larger;
}
.hoverChild {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  visibility: hidden;
  top: 7rem;
  width: 120px;
  background-color: #cecece;
  color: #000;
  width: 100%;
  height: 25%;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
}

.hoverParent {
  cursor: pointer;
  transition: all 1s;
}

.show {
  visibility: hidden;
  display: none;
  height: auto;
  width: auto;
  cursor: pointer;
}

.hoverParent:hover .show {
  display: block;
  visibility: visible;
}

.hoverParent:hover input {
  display: none;
}
.hoverParent:hover select {
  display: none;
}

/* Tooltip text */
/* .tooltip .tooltiptext { */
/* visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
} */

/* .tooltip:hover .tooltiptext {
  visibility: visible;
} */

.slideVideo {
  top: -60rem;
  transition: all 1s;
  z-index: -99;
}

@media only screen and (max-width: 1500px) {
  .slideVideo {
    top: -45rem;
  }
}

@media only screen and (max-width: 1400px) {
  .slideVideo {
    top: -35rem;
  }
}
@media only screen and (max-width: 1300px) {
  .slideVideo {
    top: -25rem;
  }
}

@media only screen and (max-width: 1200px) {
  .slideVideo {
    top: -5rem;
  }
}

/* TERMS AND CONDITIONS */

.termsPara {
  color: red;
  /* text-decoration: underline; */
  /* background-color: red; */
}

/* TERMS AND CONDITIONS END */

.video-container {
  position: relative;
  width: 100%;
  height: 30rem;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: auto;
}

.slideDiv {
  width: 100%;
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}
.slidePara {
  background-color: #00000005;
  /* backdrop-filter: blur(10px); */
  /* text-shadow: 1px 1px #000000; */

  max-width: 80%;
  text-align: center;
  /* font-size: 2rem; */
  font-size: 3rem;
  color: white;
  padding: 1.5rem;
  font-weight: bold;
  border-radius: 1rem;
}

@media only screen and (max-width: 1400px) {
  .slidePara {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 800px) {
  .slidePara {
    font-size: 1.9rem;

    padding: 1rem;
  }
}
.edit-btn {
  padding: 1rem 0;
  width: 17rem;
  font-size: 12px;
  text-align: center;
  color: #fff;
  display: inline-block;
  position: relative;
  background-color: #0e71b9;
  border: 1px solid #0e71b9;
  border-radius: 8px;
  z-index: 1;
  overflow: hidden;
  transition: 0.5s;
}

.freelancer-no {
  cursor: pointer;
}

.marquee::placeholder {
  white-space: nowrap;
  overflow: hidden;
  animation: scroll 5s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.grecaptcha-badge {
  display: none;
}

.page-btn {
  background-color: #202020;
  color: white;
  padding-inline: 1rem;
  padding-block: 0.2rem;
  font-size: 1.2rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.para3rem p {
  font-size: 1.5rem;
}

.htmlinnerpara p {
  /* background-color: #0070c0; */
  font-size: 2rem;
  color: white;
}

.section5 .cards .box p {
  font-size: 14px;
  color: var(--para);
  padding: 0.5rem;
}

.innerParaJustify {
  /* background-color: #0070c0; */
}
.innerParaJustify p {
  text-align: justify;
}
.legend {
  display: flex;
}
.legend > div {
  display: flex;
  width: 20%;
  gap: 5px;
}
.legend > div > div {
  width: 20px;
  height: 17px;
  border: 0.5px solid black;
}
.table-row > td {
  text-align: center;
}
select {
  /* background: white !important; */
  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #fff, #fff);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  background-color: white !important;
}

select:focus {
  background-image: linear-gradient(45deg, gray 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, gray 50%),
    linear-gradient(to right, #ffffff, #ffffff);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: rgb(255, 255, 255);
  outline: 0;
}

.upload-vac {
  width: 100%;
  padding: 1rem;
  display: flex;
  margin: 1rem 0;
  min-height: 4rem;
  border-radius: 5px;
  font-size: 12px;
  background: white;
  justify-content: space-between;
}
.greyImp {
  background-color: #cacaca !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.input {
  /* background-color: #0070c0; */
  font-size: 1.3rem;
}
nohover:hover {
  background-color: 00000000;
}

.termsAndConditionCMS ul li {
  list-style-type: disc;
}

.termsAndConditionCMS ul {
  margin-left: 10rem;
  margin-block: 2rem;
}

/* help and support, terms and conditions  */
.CMS_CONTENT {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  margin: 3rem;
  margin-inline: 10rem;
  gap: 2rem;
}
@media (max-width: 1199px) {
  .CMS_CONTENT {
    margin-inline: 2rem;
    gap: 1rem;
    min-height: 20vh;
  }
  .termsAndConditionCMS ul {
    margin-left: 5rem;
    margin-block: 1rem;
  }
}
